:root {
  --fuwamoco-blond: #F9F3EB;
  --fuwawa-blue: #8fdfff;
  --fuwawa-dark-blue: #3d9ec5;
  --mococo-pink: #f78dbd;
  --mococo-dark-pink: #b33f73;
}

body {
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.App {
  height: 100vh;
  position: relative;
  overflow: hidden;
}

.App::before {
  /* Pseudo-element to allow rotating the background separately */
  /* Explanation: https://www.sitepoint.com/css3-transform-background-image/ */
  content: '';
  position: absolute;
  /* Background is scaled up to ensure it covers the screen even when rotated */
  width: 200%;
  height: 200%;
  /* Offset to ensure the colour transition happens in the center */
  top: -50%;
  left: -50%;
  /* Ensure that background appears behind content */
  z-index: -1;
  /* Checkerboard background placed on top of a left to right gradient*/
  /* Checkboard pattern is a mix of transparent and white */
  /* Gradient is offset to go from left to right, accounting for the rotation */
  /* Colour stops are added to make sure that a clean colour appears on screen even with the scaling */
  background-image: repeating-conic-gradient(var(--fuwamoco-blond) 0% 25%, transparent 0% 50%), linear-gradient(60deg, var(--fuwawa-blue) 30%, var(--mococo-pink) 70%);
  /* The checkerboard is repeated with a tile-size of 90-by-90px while the gradient is not repeated */
  background-repeat: repeat, no-repeat;
  background-size: 90px 90px, auto;
  transform: rotate(30deg);
}

a {
  text-decoration: none;
}

button {
  cursor: pointer;
}

#show-settings {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

#content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#message {
  position: absolute;
  top: 50px;
  width: fit-content;
  margin: auto;
  left: 0;
  right: 0;
  padding: 10px;
  text-align: center;
  background-color: white;
  border-top: 5px solid var(--fuwawa-blue);
  border-left: 5px solid var(--fuwawa-blue);
  border-right: 5px solid var(--mococo-pink);
  border-bottom: 5px solid var(--mococo-pink);
  white-space: pre-wrap;
}

#global-bau-counter {
  font-size: 5em;
  margin-bottom: 0px;
}

#stream-status {
  margin-top: 20px;
}

#subscribe {
  margin-top: 30px;
}

.button-container {
  display: flex;
  justify-content: center;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.animated-image {
  position: absolute;
  width: 100%;
  left: 0%;
  object-fit: cover;
  opacity: 0;
}

.animated-image.front {
  opacity: 1;
}

#fuwawa,
#mococo {
  position: relative;
  height: 128px;
  width: 128px;
  cursor: pointer;
}

#fuwawa-default.play-bau-bau,
#mococo-default.play-bau-bau {
  animation: bau-bau-front 1.2s;
}

#fuwawa-bau.play-bau-bau,
#mococo-bau.play-bau-bau {
  animation: bau-bau-back 1.2s;
}

@keyframes bau-bau-front {
  0% {
    opacity: 1;
  }

  1% {
    opacity: 0;
  }

  35% {
    opacity: 0;
  }

  36% {
    opacity: 1;
  }

  40% {
    opacity: 1;
  }

  41% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

@keyframes bau-bau-back {
  0% {
    opacity: 0;
  }

  1% {
    opacity: 1;
  }

  35% {
    opacity: 1;
  }

  36% {
    opacity: 0;
  }

  40% {
    opacity: 0;
  }

  41% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

footer {
  position: absolute;
  width: 100vw;
  bottom: 0px;
  display: flex;
  justify-content: flex-end;
}

.footer-button {
  background: none;
  border: none;
  padding-bottom: 5px;
  padding-right: 10px;
  cursor: pointer;
}

